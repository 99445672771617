@import '~rsuite/dist/rsuite.min.css';

/* This is where you can set React Suite styles and options */

.rs-modal-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
  color: #707070;
}

.rs-modal-content {
  & {
    padding: 20px 0;
  }

  & .rs-modal-body {
    & {
      padding: 0 70px;
    }
  }
}

.rs-checkbox {
  & {
    padding-left: 0;
    padding-right: 0;
  }

  & .rs-checkbox-checker {
    padding-left: 24px;
  }

  & .rs-checkbox-wrapper {
    left: 0px;
  }
}
